
.carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: rgba(68, 123, 229, 0.05);
    box-shadow: inset 0px 41.0634px 58.9628px -37.9046px rgba(255, 255, 255, 0.2), inset 0px -13.6878px 9.47616px -14.7407px rgba(255, 255, 255, 0.75), inset 0px 7.37035px 11.582px -4.21163px #FFFFFF, inset 0px -86.3384px 71.5977px -67.386px rgba(96, 68, 145, 0.3), inset 0px 103.185px 105.291px -50.5395px rgba(202, 172, 255, 0.3), inset 0px 4.21163px 18.9523px rgba(154, 146, 210, 0.3), inset 0px 1.05291px 42.1163px rgba(227, 222, 255, 0.2);
    backdrop-filter: blur(105.291px);
    border-radius: 2rem;
    padding: 1.5rem 0rem;
    width: 70%;
    z-index: 999;
    overflow: hidden;
}

.carousel-image {
    display: inline;
    width: 7rem;
    margin-right: 2rem;
}

#carousel-flexbox-one {
    margin-bottom: 1rem;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .carousel-container {
        width: 100%;
    }
}

@media only screen and (min-width: 634px) and (max-width: 767px) {
    .carousel-image {
        width: 7rem;
    }
}