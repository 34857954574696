@import url('https://fonts.googleapis.com/css?family=Inter:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Spline+Sans+Mono:300,600,700');

html {
  font-size: 16px;
  background: #000;
  margin: 0;
} /*16px*/

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  margin: 0;
}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}

img {
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-section-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  border: 0.625px solid rgba(184, 154, 232, 0.40);
  background: linear-gradient(180deg, rgba(122, 61, 220, 0.00) 0.55%, rgba(21, 87, 255, 0.00) 95.31%);
  padding: 1.5rem;
  width: 30%;
}

.sub-section-text {
  font-size: 0.9rem;
  line-height: 1.8rem;
  font-weight: var(--header-font-weight);
  color: #bdbdbd;
  overflow-wrap: break-word;
}

.partner-image {
  margin: auto;
}

@media only screen and (max-width: 1483px) {

  .sub-section-container {
    width: 33%;
  }

  .sub-section-text {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 993px) {
  .sub-section-container {
    width: 85%;
  }

  .sub-section-text {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 872px) {
  .sub-section-container {
    width: 22rem;
  }

  .sub-section-text {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 499px) {
  .sub-section-container {
    width: 85%;
  }
}

@media only screen and (max-width: 430px) {
  .sub-section-container {
    width: 100%;
  }
}


/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 767px) {

  .section-container {
    width: 100%;
  }

  .section-container {
    margin-bottom: var(--row-gap);
  }

  .partner-image {
    width: 80%;
    max-height: 30%;
  }

  #aave-image {
    max-height: 25%;
  }

  #marketplace-image {
    display: none;
  }
  
}


/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 960px) {

  .partners-grid {
    column-gap: 1.5rem !important;
  }

  .partner-image {
    width: 100%;
    height: auto;
  }

  #aave-image {
    height: 70%;
  }
}



/* Laptops */
@media only screen and (min-width: 961px) and (max-width: 1211px) {

  .partner-image {
    /* width: 65%; */
    height: auto;
  }

  #aave-image {
    height: 85%;
  }

  html {
    font-size: 17px;
  }

}



/* Desktop and Wide screen */
@media only screen and (min-width: 1212px) {

  html {
    font-size: 21px;
  }

  #aave-image {
    height: 70%;
  }
}



:root {
  /* global colors */
  --background-gradient-black: linear-gradient(180deg, #000212 0%, #090512 100%);;
  --components-primary-purple: linear-gradient(96deg, #3654AA 0%, #5939CB 100%);
  --banner-gradient-blue: linear-gradient(180deg, #001964 0%, #04278E 100%);

  /* left && right page padding */
  --page-padding: 1.5rem;

  /* menu items padding */
  --menu-item-left-padding: 1rem;

  /* button padding */
  --button-px: 1rem;
  --button-py: 0.1rem;

  /* header margin font line-height */
  --header-font-weight: 600;
  --header-line-height: 1.3;

  /* h1 attributes */
  --h1-font-size: 1.7rem;

  /* h2 attributes */
  --h2-font-size: 1.35rem;

  /* p attributes */
  --p-font-size: 1rem;

  /* p2 attributes */
  --p2-font-size: 0.8rem;

  /* grid item bottom margin */
  --grid-item-bm: 1.75rem;

  /* vertical grid spacing */
  --row-gap: 3.5rem;

  /* section bottom margin */
  --section-bm: 5rem;
}