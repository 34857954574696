
.details-container {
    overflow: hidden;
    margin-bottom: 5rem;
    z-index: 999;
}


@media only screen and (min-width: 768px) and (max-width: 960px) {

}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    
}