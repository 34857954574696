
.main {
  position: relative;
  height: 100%;
  background: var(--background-gradient-black);
  overflow: hidden;
  margin: auto;
}

.container {
  max-width: 1600px;
  margin: auto;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@media only screen and (min-width: 466px) and (max-width: 767px) {
  .sash {
    height: 31rem;
  }
}

@media only screen and (max-width: 465px) {
  .sash {
    height: 38rem;
  }
}